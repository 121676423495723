import { DoctAutoComplete, DoctChip, DoctTextField } from '@doct-react/app';
import { DoctCol, DoctRow, DoctTypography } from '@doct-react/core';
import React, { useCallback, useState } from 'react';
import useFieldOptions from '../../../../../../hooks/useFieldOptions/useFieldOptions';
import employmentTypeOption from '../../../static/employmentTypeOptions';
import DoctFreeSoloAutoCompleteWithChips from '../../../../../../shared/components/DoctFreeSoloAutoCompleteWithChips';

const JobOverviewAdditionalInfo = ({
  control,
  errors,
  qualificationArray,
  setQualificationArray,
  watch,
  setValue,
  register,
}) => {
  const [qualificationsSearchText, setQualificationsSearchText] = useState('');

  const { optionsArray: qualificationOptions } = useFieldOptions({
    apiRoute: '/qualifications/names',
    searchText: qualificationsSearchText,
  });

  const qualificationEntry = watch('qualificationId');

  const onQualificationValueChange = useCallback(
    (selectedQualification) => {
      if (
        selectedQualification &&
        typeof selectedQualification === 'object' &&
        selectedQualification.value !== undefined &&
        !qualificationArray.some((item) => item && item.value === selectedQualification.value)
      ) {
        setQualificationArray((prevState) => [...prevState, selectedQualification]);
      }
      setValue('qualificationId', null);
      setQualificationsSearchText('');
    },
    [qualificationArray, setValue],
  );

  const handleChange = (val = '') => {
    if (typeof val === 'string') {
      setQualificationsSearchText(val);
    } else if (val && typeof val === 'object' && val.value !== undefined) {
      onQualificationValueChange(val);
    } else if (val === null) {
      setValue('qualificationId', null);
      setQualificationsSearchText('');
    }
  };

  const disableSelectedQualification = (qualification) => {
    return !!qualificationArray.find((element) => element?.value === qualification?.value);
  };

  const removeQualification = (qualification) => {
    setQualificationArray(
      qualificationArray?.filter((existingQualification) => existingQualification != qualification),
    );
  };

  return (
    <>
      <DoctRow>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">Employment Type</DoctTypography>
          <DoctAutoComplete
            control={control}
            isErrors={errors}
            options={employmentTypeOption}
            validationRules={{ required: 'it is a required field' }}
            name="employementType"
            id="employementType"
            label="Select"
          />
        </DoctCol>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">No. of Vacancies</DoctTypography>
          <DoctTextField
            name="noOfVacancy"
            id="noOfVacancy"
            label="Enter Number"
            validationRules={{
              required: 'it is required field',
              pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                message: 'Number is not valid',
              },
              maxLength: {
                value: 2,
                message: 'Max 2 Characters allowed',
              },
            }}
            control={control}
            isErrors={errors}
            showStar={false}
          />
        </DoctCol>
      </DoctRow>

      <DoctTypography variant="subtitle2">Preferred Education</DoctTypography>
      <DoctTypography variant="body2" className="text-grey-600">
        Select from the list or add custom.
      </DoctTypography>

      <DoctFreeSoloAutoCompleteWithChips
        register={register}
        field="qualificationId"
        validationRules={{}}
        options={qualificationOptions}
        control={control}
        errors={errors}
        label="Select"
        className="mb-2"
        handleChange={handleChange}
        value={qualificationEntry}
        getOptionDisabled={(option) => disableSelectedQualification(option)}
      />

      {qualificationArray?.length > 0 && (
        <>
          <div className="d-flex flex-sm-wrap">
            {qualificationArray?.map((qualification, i) => (
              <div className="m-1" key={i}>
                <DoctChip
                  title={qualification?.label}
                  onCloseHandler={() => removeQualification(qualification)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default JobOverviewAdditionalInfo;
