import { DoctForm } from '@doct-react/app';
import { useDispatch } from 'react-redux';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../constants/constants';
import { TOST_TOP_RIGHT } from '../../../../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../../../featuresSlice';
import { useUpdateAttendeeMutation } from '../services/manageAttendess.services';
import { filedManageAttendee } from './field.ManageAttendee';
import { WORK_STATUS_TYPE } from '../../../../helper/enums/eventStatus';

const defaultValues = {
  mobileCountryCode: {
    label: '+91',
  },
  whatsappCountryCode: {
    label: '+91',
  },
  [filedManageAttendee.workStatus.name]: WORK_STATUS_TYPE.WorkingProfessional,
};

export const useManageAttendeeForm = (attendeeObj, setAttendeeObj) => {
  const [
    updateAttendee, // This is the mutation trigger
    { isLoading: isUpdating, isError, isSuccess }, // This is the destructured mutation result
  ] = useUpdateAttendeeMutation();

  const { handleSubmit, control, errors, register, watch, setValue, touched, clearErrors, reset } =
    DoctForm({
      mode: 'onChange',
      defaultValues: defaultValues,
    });

  const dispatch = useDispatch();

  const handleFormSubmit = handleSubmit((values, attendeeObj) => {
    values['specialtyIds'] = [values?.specialtyIds?.value];
    const transformObj = {
      ...attendeeObj,
      ...values,
      country: values.country?.label,
      state: values.state?.label,
      city: values.city?.label,
      phoneNo: {
        countryCode: values.mobileCountryCode?.label,
        number: values.mobileNumber,
      },
      whatsAppNumber: {
        countryCode: values.whatsappCountryCode?.label,
        number: values.whatsAppNumber,
      },
    };
    if (transformObj?.workStatus === WORK_STATUS_TYPE.Student) {
      delete transformObj.currentDesignation;
      delete transformObj.currentOrganization;
    }
    updateAttendee(transformObj).then((response) => {
      if (Object.prototype.hasOwnProperty.call(response, 'data')) {
        dispatch(
          setShowToasterWithParams({
            variant: 'positive',
            position: TOST_TOP_RIGHT,
            message: 'Record updated successfully!',
          }),
        );
        setAttendeeObj(null);
        return;
      }
      if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        const { error: { data: { Title } = {} } = {} } = response || {};
        dispatch(
          setShowToasterWithParams({
            variant: 'danger',
            position: TOST_TOP_RIGHT,
            message: Title || NETWORK_REQUEST_ERROR_MSG,
          }),
        );
      }
    });
  });

  return {
    handleFormSubmit,
    control,
    errors,
    register,
    watch,
    setValue,
    touched,
    clearErrors,
    reset,
    isUpdating,
    isError,
    isSuccess,
  };
};
